import React from "react";
import {Link} from 'gatsby';

const Landing = (props) => {

    return (
        <div className="landing">
            <div className='landing-first-half'>
                <div>
                    <h1>Market News made simple</h1>
                    <span>
                        Every week we bring you the most important developments from the
                        Indian markets in a language  you can understand. Simple.
                        No Nonsense. To the point. That's the promise.
                    </span>
                    <br />
                    <button>
                        <Link style={{fontFamily:`Roboto`}} to="#past_issues">Past Issues</Link>
                    </button>
                </div>
            </div>
            <div style={{ width: '4%' }}></div>
            <div className='landing-second-half'>
                <div style={{ position: 'relative', left: 0, top: 0 }}>
                    <img className='phone-background' src='/images/landingFold.png'/>
                    <img className='phone' src='/images/phone.png' />
                </div>
            </div>
        </div>
    )
}

export default Landing;