import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
    FaLinkedin,
    FaTwitter,
    FaWhatsapp,
    FaInstagram,
    FaYoutube,
    FaRegHandPointRight
} from "react-icons/fa";

import Landing from '../Landing';
import Header from '../Header';
import FinshotsDailySection from "../FinshotsDaily";

import TopArticles from '../TopArticles';

import config from '../../utils/siteConfig';

import { Navigation } from ".";

// Styles
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome, pageNumber, slug }) => {
    const site = data.allGhostSettings.edges[0].node;

    const [titleText, setTitleText] = useState('')

    useEffect(() => {
        var title = document.querySelector('.content-title');

        if (title) {
            setTitleText(document.getElementById('post-title').innerHTML);

            var progressBar = document.querySelector('#reading-progress');
            var header = document.querySelector('.floating-header');

            var lastScrollY = window.scrollY;
            var lastWindowHeight = window.innerHeight;
            var lastDocumentHeight = document.body.clientHeight
            var ticking = false;

            function onScroll() {
                lastScrollY = window.scrollY;
                requestTick();
            }

            function onResize() {
                lastWindowHeight = window.innerHeight;
                lastDocumentHeight = document.body.clientHeight
                requestTick();
            }

            function requestTick() {
                if (!ticking) {
                    requestAnimationFrame(update);
                }
                ticking = true;
            }

            function update() {
                var trigger = title.getBoundingClientRect().top + window.scrollY;
                var triggerOffset = title.offsetHeight + 35;
                var progressMax = lastDocumentHeight - lastWindowHeight;

                // show/hide floating header
                if (lastScrollY >= trigger + triggerOffset) {
                    header.classList.add('floating-active');
                } else {
                    header.classList.remove('floating-active');
                }

                progressBar.setAttribute('max', progressMax);
                progressBar.setAttribute('value', lastScrollY);

                ticking = false;
            }

            window.addEventListener('scroll', onScroll, { passive: true });
            window.addEventListener('resize', onResize, false);

            update();
        }
    })

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">

                {/* The main header section on top of the screen */}
                {<Header site={site} isHome={isHome} />}

                {/** Floating Header with Progress Bar */}
                <div className="floating-header">
                    <div className="floating-header-logo">
                        <a href='/'>
                            <img
                                className="site-logo"
                                src={`/favicon.png`}
                                alt={site.title}
                            />
                        </a>
                    </div>
                    <span className="floating-header-divider"><strong>Finshots</strong> &mdash;</span>
                    <div className="floating-header-title">
                        {titleText && titleText}
                    </div>

                    <div className="floating-header-share">
                        <div className="floating-header-share-label">Share this <FaRegHandPointRight /></div>
                        <a className="floating-header-share-tw"
                            onClick={() => {
                                window.open(`https://twitter.com/share?text=${encodeURI(titleText)}&url=${encodeURI(config.siteUrl + '/' + slug)} via @finshots`, 'share-twitter', 'width=550,height=235')
                                return false;
                            }}>
                            <FaTwitter
                                style={{ fontSize: `20px`, color: `#ffffff` }}
                            />
                        </a>
                        <a className="floating-header-share-wa"
                            onClick={() => {
                                window.open(`https://api.whatsapp.com/send?text=${titleText + ' ' + config.siteUrl + '/' + slug + `%3Futm_source%3DtopCornerMarkets%26utm_medium%3Dwhatsapp`}`, 'share-whatsapp', 'width=580,height=296')
                                return false;
                            }}>
                            <FaWhatsapp
                                style={{ fontSize: `20px`, color: `#ffffff` }}
                            />
                        </a>
                    </div>

                    <progress id="reading-progress" className="progress" value="0">
                        <div className="progress-container">
                            <span className="progress-bar"></span>
                        </div>
                    </progress>
                </div>

                <div className="viewport-top">

                    {isHome && !pageNumber &&
                        <Landing />
                    }

                    <main className="site-main" id="past_issues">
                        {isHome && !pageNumber && <h1>Past Issues</h1>}
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                        <FinshotsDailySection />
                    </main>
                </div>

                <div className="viewport-bottom">
                    {!isHome &&
                        <div className="container top-articles">
                            <h2>Top Articles from Finshots Daily</h2>
                            <TopArticles />
                        </div>
                    }
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <a href="/">
                                    <img
                                        className="site-logo"
                                        src={`https://d3jlwjv6gmyigl.cloudfront.net/assets/two/images/logo-2d6831bc63e1ecb76091541e2f20069a.png`}
                                        alt={site.title}
                                    />
                                </a>
                                <div style={{ marginTop: `20px` }}>
                                    <a href='https://apps.apple.com/us/app/finshots/id1508510456'>
                                        <img className='app-store' alt='Download on the App Store' src='/images/App_store_badge.svg' />
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=com.finception.finshots.android&utm_source=FinshotsMarketsWebsite&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img className='play-store' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                    </a>
                                </div>

                            </div>
                            <div className="site-foot-nav-right">
                                <a href="https://twitter.com/finshots">
                                    <FaTwitter
                                        style={{ fontSize: `40px`, color: `#1DA1F2` }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/showcase/finshots/">
                                    <FaLinkedin
                                        style={{ fontSize: `40px`, color: `#2867B2` }}
                                    />
                                </a>
                                <a href='https://www.instagram.com/finshots.in'>
                                    <FaInstagram
                                        style={{ fontSize: `40px`, color: `#E1306C` }}
                                    />
                                </a>
                                <a href='https://www.youtube.com/channel/UCiF-ERY5sn5QTK9hhH9vsVg'>
                                    <FaYoutube
                                        style={{ fontSize: `40px`, color: `#FF0000` }}
                                    />
                                </a>
                            </div>
                        </div>

                        <div className='footer-text container'>
                            <p>
                                <strong>Disclosure:</strong> All content provided in this website is for
                                informational and educational purposes only and is not meant
                                to represent trade or investment recommendations.
                            </p>
                        </div>
                    </footer>
                </div>
            </div >
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
