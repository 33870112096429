import React, { useState } from "react";
import { Link } from "gatsby";
import { slide as Menu } from 'react-burger-menu';

const Header = (props) => {

    const { site, isHome } = props;
    const [menuState, setMenuOpen] = useState({ menuOpen: false });

    const closeMenu = () => {
        setMenuOpen({ menuOpen: false })
    }

    return (
        <div style={{ zIndex: 5}}>
            <header
                className="site-head"
            >
                <div className="header">
                    <div className="site-mast">
                        <div className="site-mast-left">
                            <Link to="/">
                                <img
                                    className="site-logo"
                                    src={`/images/finshots_zerodha.png`}
                                    alt={site.title}
                                />
                            </Link>
                        </div>
                        <div className="site-mast-right">
                            <div className="site-nav-item">
                                <Link to="/#past_issues">
                                    Past Issues
                                </Link>
                            </div>
                            <div className="site-nav-item">
                                <a href={encodeURI('https://finshots.in?utm_source=finshotsMarkets&utm_medium=webHeader')} target="_blank" rel="noreferrer">
                                    Finshots Daily
                                </a>
                            </div>
                            <div className="site-nav-item">
                                <a href={encodeURI('https://joinditto.in?utm_source=finshotsMarkets&utm_medium=webHeader')} target="_blank" rel="noreferrer">
                                    Ditto Insurance
                                </a>
                            </div>
                        </div>
                        <div className="site-mast-right-mobile" >
                            <img src="/images/hamburger.png" alt="hamburger" onClick={() => setMenuOpen({ menuOpen: true })} />
                            <div className="top-nav">
                                <div className="side-menu">
                                    <Menu
                                        isOpen={menuState.menuOpen} onStateChange={(state) => setMenuOpen({ menuOpen: state.isOpen })}
                                        right
                                    >
                                        <Link to="/#past_issues" onClick={() => closeMenu()}>
                                            Past Issues
                                        </Link>
                                        <a href={encodeURI('https://finshots.in?utm_source=finshotsMarkets&utm_medium=webHeader')} target="_blank" rel="noreferrer" onClick={() => closeMenu()}>
                                            Finshots Daily
                                        </a>
                                        <a href={encodeURI('https://joinditto.in?utm_source=finshotsMarkets&utm_medium=webHeader')} target="_blank" rel="noreferrer" onClick={() => closeMenu()}>
                                            Ditto Insurance
                                        </a>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header;