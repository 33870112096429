import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { PostCard } from '../../components/common';

/**
* Top Articles Section (At the bottom of posts)
*/
export default function TopArticles() {
    const data = useStaticQuery(graphql`
        query {
            allGhostPost(
                filter: {tags: {elemMatch: {slug: {eq: "top-articles"}}}} 
                sort: {order: DESC, fields: [published_at]}
                limit: 3
            ) {
                edges {
                    node {
                        ...GhostPostFields
                    }
                }
            }
        }
    `)

    return (
        <section className="post-feed">
            {data?.allGhostPost?.edges.map(({ node }) => (
                // The tag below includes the markup for each post - components/common/PostCard.js
                <PostCard key={node.id} post={node} topArticles={true} />
            ))}
        </section>
    )
}
